/* eslint-disable react-hooks/exhaustive-deps */
import React, { type ReactNode, useEffect } from "react";
import { CloseIcon } from "./SvgIcons";
import Confetti from "react-confetti";
import { useWindowSize } from "../utils/util";
import { Howl } from "howler";
import Image from "next/image";

interface ModalProps {
  isOpen: boolean;
  multiplier: number;
  getAmount: number;
  onClose: () => void;
  children?: ReactNode;
  token: string;
}

const WinPopup: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  multiplier,
  getAmount,
  token,
}) => {
  const { width, height } = useWindowSize();
  const winSound = new Howl({
    src: "../sounds/play-1.wav",
    volume: 0.1,
  });

  const clapSound = new Howl({
    src: "../sounds/clap.wav",
    volume: 0.1,
  });

  useEffect(() => {
    if (isOpen) {
      winSound.play();
      clapSound.play();
    } else {
      winSound.stop();
      clapSound.stop();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center text-white">
      {isOpen && <Confetti width={width} height={height} />}
      <div className="fixed inset-0 left-0 top-0 h-screen w-screen backdrop-blur-lg "></div>
      <div className="relative h-[400px] w-[640px] max-md:w-[550px] max-sm:w-[450px] max-[500px]:w-[380px] max-[400px]:w-[300px]">
        <Image
          src={"/images/cong-bg.png"}
          className="hue-rotate-1 absolute h-full w-full"
          alt="Winning background"
          width={640}
          height={400}
        />
        <button
          className="absolute right-2 top-2 z-30 mr-4 mt-3 text-gray-700 hover:text-gray-900"
          onClick={() => {
            // onReset();
            winSound.stop();
            clapSound.stop();
            onClose();
          }}
        >
          <CloseIcon color="#fff" />
        </button>
        <div className="relative z-20 flex h-full flex-col items-center justify-center p-10">
          <div className="mt-6 flex-col items-center gap-6">
            <div className="text-center text-[70px] font-bold leading-[70px] text-yellow-200 max-sm:text-[36px]">
              {multiplier} X
            </div>
            <div className="text-center text-[70px] font-bold uppercase leading-[70px] text-yellow-200 max-sm:text-[36px]">
              {getAmount} {token}
            </div>
          </div>
          <div className="mt-5 text-center">
            <button
              className="rounded- h-10 w-[200px] bg-yellow-300 px-4 text-lg font-black uppercase text-black"
              onClick={() => {
                winSound.stop();
                clapSound.stop();
                onClose();
              }}
            >
              continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinPopup;
