/* eslint-disable @next/next/no-img-element */
/* eslint-disable react-hooks/exhaustive-deps */
import { useWallet } from "@meshsdk/react";
import { Howl } from "howler";
import { useSession } from "next-auth/react";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { env } from "~/env.mjs";
import { api } from "~/utils/api";
import { useGameStore } from "~/utils/zustand";
import {
  generateRandomNumbers,
  getCurrentCoinSettings,
  sleep,
  useWindowSize,
} from "../utils/util";
import CoinValue from "./CoinValue";
import GameFee from "./GameFee";
import { LeaderBoard } from "./LeaderBoard";
import SlotCell from "./SlotCell";
import { SYMBOLS, type SymbolItem } from "./Symbols";
import TokenDropdown from "./TokenDropdown";
import WinPopup from "./WinPopup";
import WinsChart from "./WinsChart";
import { cn } from "~/utils/shadcn";
import Image from "next/image";

const SlotsContainer: React.FC = () => {
  const { connected } = useWallet();
  const { width } = useWindowSize();
  const [positionY, setPositionY] = useState(0);

  const {
    gameBalance,
    currentSelectedToken,
    decreaseGameBalance,
    increaseGameBalance,
  } = useGameStore();
  const { data: sessionData } = useSession();

  const [blur, setBlur] = useState(10);
  const [randomFlag, setRandomFlag] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [coinValue, setCoinValue] = useState<number>(10);

  const [isStarted, setIsStarted] = useState(false);
  const [blockSpinBtn, setBlockSpinBtn] = useState(false);

  const [isWinsChart, setIsWinsChart] = useState(false);
  const [isWinModal, setIsWinModal] = useState(false);
  const [isGameFee, setIsGameFee] = useState(false);
  const [isLeaderboard, setIsLeaderboard] = useState(false);

  const [isSpinMoveEnd, setIsSpinMoveEnd] = useState(true);
  const [spinResult, setSpinResult] = useState([] as number[]);

  const { mutateAsync: playGame } = api.game.play.useMutation();

  const { data: tokenSettings } = api.config.tokenSettings.useQuery();
  const currentCoinSettings = getCurrentCoinSettings(
    currentSelectedToken,
    tokenSettings?.settings ?? [],
  );

  const playSound = new Howl({
    src: "/sounds/play-3.wav",
    volume: 0.05,
  });

  const [gameResult, setGameResult] = useState({
    getAmount: 0,
    multiplier: 0,
  });

  const gameHeight = useMemo(() => {
    let h = 0;
    if (width > 1024) {
      h = 7210;
      return h;
    } else if (width > 768) {
      h = 5750;
      return h;
    } else if (width > 640) {
      h = 4800;
      return h;
    } else if (width > 450) {
      h = 3830;
      return h;
    } else if (width > 400) {
      h = 3830;
      return h;
    } else {
      h = 3840;
      return h;
    }
  }, [width]);

  const shuffleArray = (array: SymbolItem[]) => {
    const newArray = array.slice();
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j]!, newArray[i]!];
    }
    return newArray;
  };

  const generateCellRow = (isSnek: boolean) => {
    const allSymbols = SYMBOLS(isSnek);
    const cellRow = shuffleArray(allSymbols.concat(allSymbols));
    return cellRow;
  };

  const createResultArray = (
    list: SymbolItem[][],
    spinResult: number[],
    isSnek: boolean,
  ) => {
    const resArray = [];
    for (let i = 0; i < 5; i++) {
      // Get column results
      const item = spinResult.slice(i * 3, i * 3 + 3);
      const s = []; // final result to display
      for (let j = 0; j < 3; j++) {
        // find correct symbol
        const symbol = SYMBOLS(isSnek).find((s) => s.id === item[j]);

        if (!symbol) {
          throw new Error("Unable to find symbol with id: " + item[j]);
        }

        s.push(symbol);
      }
      resArray[i] = [...list[i]!, ...s];
    }
    return resArray;
  };

  const cells = useMemo(() => {
    const list = [];
    const isSnek = currentSelectedToken === "SNEK";

    for (let i = 0; i < 5; i++) {
      list.push(generateCellRow(isSnek));
    }

    const resArray = createResultArray(
      list,
      isStarted ? spinResult : generateRandomNumbers(15),
      isSnek,
    );

    return resArray;
  }, [spinResult, currentSelectedToken]);

  useEffect(() => {
    setCoinValue(Number(currentCoinSettings.minBet));
  }, [currentSelectedToken]);

  const onReset = async () => {
    setIsSpinMoveEnd(true);
    playSound.stop();
    setIsReset(true);
    setBlur(10);
    setPositionY(0);
    await sleep(1);
  };

  const spinEffect = async (time: number) => {
    await sleep(1);
    playSound.play();
    setIsSpinMoveEnd(false);
    setIsReset(false);
    setBlur(0);
    setRandomFlag(!randomFlag);
    if (gameHeight) {
      setPositionY(gameHeight);
    }
    await sleep(time);
    setIsSpinMoveEnd(true);
  };

  const handleSpin = async () => {
    if (sessionData) {
      if (coinValue < Number(currentCoinSettings.minBet)) {
        toast.error(
          `You need to bet at least ${currentCoinSettings.minBet.toString()} ${
            currentCoinSettings.tokenName
          }`,
        );
        return;
      } else if (coinValue > Number(currentCoinSettings.maxBet)) {
        toast.error(
          `You can only bet up to ${currentCoinSettings.maxBet.toString()} ${
            currentCoinSettings.tokenName
          }`,
        );
        return;
      }
      if (gameBalance[currentSelectedToken] < coinValue) {
        toast.error("Deposit more or Decrease bet amount");
      } else if (
        gameBalance[currentCoinSettings.feeToken] <
          Number(currentCoinSettings.feeAmount) ||
        (gameBalance[currentSelectedToken] ==
          gameBalance[currentCoinSettings.feeToken] &&
          gameBalance[currentSelectedToken] <
            coinValue + Number(currentCoinSettings.feeAmount))
      ) {
        toast.error("Not enough balance to pay fee");
      } else {
        setBlockSpinBtn(true);
        const res = await playGame({
          token: currentSelectedToken,
          betAmount: coinValue,
        });

        if (res.success) {
          decreaseGameBalance(currentSelectedToken, coinValue);
          decreaseGameBalance(
            currentCoinSettings.feeToken,
            Number(currentCoinSettings.feeAmount),
          );

          setIsStarted(true);
          setSpinResult(res.result);

          setGameResult({
            getAmount: res.bet.getAmount,
            multiplier: res.bet.multiplier,
          });

          await onReset();
          await spinEffect(20000);

          if (res.bet.multiplier > 0) {
            setIsSpinMoveEnd(true);
            playSound.stop();
            setIsWinModal(true);
            toast.success("Out of this World!");
            setIsSpinMoveEnd(true);
            increaseGameBalance(currentSelectedToken, res.bet.getAmount);
          } else {
            toast.warning("Better Luck Next Time");
          }
        } else {
          toast.error(res.error);
          console.error(res.error);
        }

        setBlockSpinBtn(false);
      }
    } else {
      toast.error("Please connect wallet");
    }
  };

  const handleSetMin = () => {
    setCoinValue(Number(currentCoinSettings.minBet));
  };

  const handleSetHalf = () => {
    setCoinValue(Math.max(Number(currentCoinSettings.minBet), coinValue / 2));
  };

  const handleSetDouble = () => {
    setCoinValue(Math.min(Number(currentCoinSettings.maxBet), coinValue * 2));
  };

  const handleSetMax = () => {
    setCoinValue(Number(currentCoinSettings.maxBet));
  };

  const handleOpenLeaderboard = () => {
    setIsLeaderboard(true);
  };

  return (
    <>
      <div
        className={cn(
          "relative z-10 mb-20 flex w-full flex-col items-center justify-center p-6",
          connected && sessionData ? "pt-32" : "pt-44",
        )}
      >
        <div className="flex flex-col items-center">
          {connected && sessionData && (
            <div className="cell-border mb-32 flex w-full flex-col items-center px-10">
              <span className="border-span" />
              <span className="border-span" />
              <span className="border-span" />
              <span className="border-span" />
              <div className="hue-rotate font-black uppercase text-[#b5994d]">
                balance{" "}
                {env.NEXT_PUBLIC_OWNER_WALLET.startsWith("addr_test") &&
                  "--- PREPROD ENVIRONMENT ---"}
              </div>
              <div className="grid w-full grid-cols-1 justify-items-center gap-4 sm:grid-cols-6 sm:grid-rows-2">
                {[
                  {
                    label: "ada",
                    balance: gameBalance.ADA,
                    customCss: "sm:col-span-2",
                  },
                  {
                    label: "dum",
                    balance: gameBalance.DUM,
                    customCss: "sm:col-span-2",
                  },
                  {
                    label: "snek",
                    balance: gameBalance.SNEK,
                    customCss: "sm:col-span-2",
                  },
                  {
                    label: "konda",
                    balance: gameBalance.KONDA,
                    customCss: "sm:col-start-2 sm:col-end-3",
                  },
                  {
                    label: "nebula",
                    balance: gameBalance.NEBULA,
                    customCss: "sm:col-start-4 sm:col-end-6",
                  },
                ].map(({ label, balance, customCss }) => (
                  <div
                    className={cn(
                      "flex items-center gap-2 text-center font-black uppercase text-[#fff]",
                      customCss,
                    )}
                    key={label}
                  >
                    <div className="text-center text-sm">{label}:</div>
                    <div className={`text-md text-left text-[yellow]`}>
                      {!isStarted && balance.toFixed(2)}
                      {gameResult.multiplier !== 0 &&
                        !isSpinMoveEnd &&
                        balance.toFixed(2)}
                      {gameResult.multiplier !== 0 &&
                        isSpinMoveEnd &&
                        balance.toFixed(2)}
                      {gameResult.multiplier == 0 &&
                        isStarted &&
                        balance.toFixed(2)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div>
            <div className="relative w-[800px] max-lg:w-[600px] max-md:w-[460px] max-sm:w-[320px] max-[400px]:w-[300px]">
              {currentSelectedToken === "SNEK" ? (
                <Image
                  src={"/images/Snek.png"}
                  className="absolute -left-[41px] -top-[65px] z-20 h-[380px] min-w-[371px] min-[400px]:-left-[49px] min-[400px]:-top-[67px] min-[400px]:h-[403px] min-[400px]:min-w-[429px] sm:-left-[65px] sm:-top-[81px] sm:h-[470px] sm:min-w-[610px] md:-left-[95px] md:-top-[100px] md:h-[581px] md:min-w-[790px] lg:-left-[115px] lg:-top-[115px] lg:h-[690px] lg:w-[1040px] lg:max-w-[1040px]"
                  alt="Snek Themed Game Border"
                  width={1040}
                  height={690}
                />
              ) : (
                <Image
                  src={"/images/border.png"}
                  className="hue-rotate absolute -left-[19px] -top-[35px] z-20 h-[322px] min-w-[338px] min-[400px]:-top-[36px] min-[400px]:h-[343px] min-[400px]:min-w-[370px] sm:-left-[35px] sm:-top-[45px] sm:h-[400px] sm:min-w-[545px] md:-left-[35px] md:-top-[60px] md:h-[500px] md:min-w-[676px] lg:-left-[55px] lg:-top-[62px] lg:h-[585px] lg:w-[915px] lg:max-w-[915px]"
                  alt="Game Border"
                  width={915}
                  height={585}
                />
              )}
              <div className="absolute -top-20 left-0 z-30 max-sm:-top-14">
                <p className="-ml-4 text-sm text-white max-sm:ml-0 max-sm:hidden">
                  PAY TABLE
                </p>
                <button
                  className="zoom-in"
                  onClick={() => setIsWinsChart(true)}
                >
                  <Image
                    src={"/images/info.png"}
                    className="h-10 w-10 sm:h-12 sm:w-12"
                    alt="Info icon"
                    width={48}
                    height={48}
                  />
                </button>
              </div>
              <div className="absolute -top-20 right-10 z-30 -mr-8 flex-col items-center max-sm:-top-14 sm:right-0">
                <p className={`text-sm text-white max-sm:hidden`}>
                  RACE LEADERS
                </p>
                <div className="flex justify-center">
                  <button className="zoom-in" onClick={handleOpenLeaderboard}>
                    <Image
                      src={"/images/ranking.png"}
                      className="h-10 w-10 sm:h-12 sm:w-12"
                      alt="Ranking icon"
                      width={48}
                      height={48}
                    />
                  </button>
                </div>
              </div>
              <div className="hue-rotate absolute -top-5 right-16 z-50 sm:-top-8 sm:right-24 md:-top-11 md:right-36 lg:-top-10 lg:right-52">
                <img
                  src={"/images/title.png"}
                  className="h-[20px] w-[175px] sm:h-[30px] sm:w-[250px] md:h-[40px] md:w-[290px] lg:w-[390px]"
                  alt="Space Race title"
                />
              </div>
              <TokenDropdown />
              <CoinValue
                title="Coin value"
                coinValue={coinValue}
                className="absolute -bottom-11 right-[80px] z-30 w-[200px] max-lg:-bottom-6 max-lg:right-[40px] max-md:-bottom-0 max-md:right-0 max-md:w-[150px] max-sm:-bottom-8 max-sm:right-0 max-sm:w-[130px]"
                setCoinValue={setCoinValue}
              />

              <button
                title="Spin"
                className={`absolute -bottom-[60px] left-1/2 z-30  mt-5 grid h-[100px] w-[100px] -translate-x-1/2  place-content-center rounded-full transition-all duration-300 hover:scale-[1.1] disabled:cursor-not-allowed max-lg:-bottom-[35px] max-lg:h-[80px] max-lg:w-[80px] max-md:-bottom-[5px] max-md:left-[240px] max-md:h-[60px] max-md:w-[60px] max-sm:-bottom-[37px] max-sm:left-[165px] max-sm:h-[40px] max-sm:w-[40px] max-[400px]:left-[150px] ${
                  currentSelectedToken === "ADA"
                    ? "bg-yellow-500  hover:bg-yellow-600 "
                    : "bg-gray-500 hover:bg-gray-600 "
                }`}
                onClick={handleSpin}
                disabled={
                  !isSpinMoveEnd ||
                  !connected ||
                  gameBalance[currentSelectedToken] <
                    Number(currentCoinSettings.minBet) ||
                  blockSpinBtn
                }
              >
                {currentSelectedToken === "ADA" ? (
                  <Image
                    src="/images/spin.png"
                    className="relative"
                    alt="Spin icon"
                    width={100}
                    height={100}
                  />
                ) : (
                  <Image
                    src="/images/snek-spin.png"
                    className="relative"
                    alt="Snek spin icon"
                    width={100}
                    height={100}
                  />
                )}
                <p
                  className={`absolute right-3  top-8 text-3xl font-semibold max-lg:right-2 max-lg:top-6 max-lg:text-2xl max-md:right-2 max-md:top-4 max-md:text-lg max-sm:hidden ${
                    currentSelectedToken === "ADA" ? "text-black" : "text-white"
                  }`}
                >
                  SPIN
                </p>
              </button>
              <div className="cell-border relative z-10 flex h-[460px] w-[800px] gap-1 max-lg:h-[400px] max-lg:w-[600px] max-md:h-[350px] max-md:w-[475px] max-sm:h-[270px] max-sm:w-[330px] max-[400px]:h-[250px] max-[400px]:w-[300px]">
                {cells.map((item, key) => (
                  <SlotCell
                    key={key}
                    y={positionY}
                    delay={0.6 * (key + 1)}
                    blur={blur}
                    symbols={item}
                    isReset={isReset}
                    isSpinMoveEnd={isSpinMoveEnd}
                  />
                ))}
              </div>
            </div>
            <div className="flex w-full justify-between max-lg:pl-2">
              <p className="max-md:text-md relative -right-32 top-16 text-xl font-semibold text-white max-lg:-right-24 max-lg:top-12 max-lg:text-lg max-md:-right-20 max-md:top-3 max-sm:-right-8 max-sm:top-10 max-sm:text-sm max-[400px]:hidden">
                TOKEN
              </p>
              <p className="max-md:text-md relative right-40 top-16 text-xl font-semibold text-white max-lg:right-32 max-lg:top-12  max-lg:text-lg max-md:right-14 max-md:top-3 max-sm:right-11 max-sm:top-10 max-sm:text-sm max-[400px]:hidden">
                BET
              </p>
            </div>

            <p className="text-md cell-border relative top-12 !bg-background/50 text-center font-semibold md:text-lg lg:top-16 lg:text-2xl">
              Spin Fee:{" "}
              <span>
                {`${currentCoinSettings.feeAmount.toString()} ${
                  currentCoinSettings.feeToken
                }`}
              </span>
            </p>

            <div className="cell-border hue-rotate relative top-20 mx-auto flex w-[80%] items-center justify-between rounded-lg px-10 py-2">
              <span className="border-span" />
              <span className="border-span" />
              <span className="border-span" />
              <span className="border-span" />
              <p
                className="text-md cursor-pointer text-white"
                onClick={handleSetMin}
              >
                MIN
              </p>
              <p
                className="text-md cursor-pointer text-white"
                onClick={handleSetHalf}
              >
                1/2
              </p>
              <p
                className="text-md cursor-pointer text-white"
                onClick={handleSetDouble}
              >
                2X
              </p>
              <p
                className="text-md cursor-pointer text-white"
                onClick={handleSetMax}
              >
                MAX
              </p>
            </div>
            <div className="relative top-20 flex items-center">
              <button
                className="z-100 absolute -left-3 -top-14 max-sm:-top-10 "
                onClick={() => setIsGameFee(true)}
              >
                <p className="-ml-4 text-sm text-white max-sm:hidden ">
                  SPIN FEE
                </p>
                <Image
                  src={"/images/fee.png"}
                  className="h-8 w-8"
                  alt="Fee icon"
                  width={32}
                  height={32}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <WinsChart
        isOpen={isWinsChart}
        onClose={() => setIsWinsChart(false)}
        token={currentSelectedToken}
      />
      <WinPopup
        isOpen={isWinModal}
        onClose={() => setIsWinModal(false)}
        multiplier={gameResult.multiplier}
        getAmount={gameResult.getAmount}
        token={currentSelectedToken}
      />
      <GameFee isOpen={isGameFee} onClose={() => setIsGameFee(false)} />
      <LeaderBoard
        isOpen={isLeaderboard}
        onClose={() => setIsLeaderboard(false)}
      />
    </>
  );
};

export default SlotsContainer;
