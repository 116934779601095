import { type ReactNode } from "react";
import { CloseIcon } from "./SvgIcons";
import { type SupportedToken } from "@prisma/client";
import ClickAwayComponent from "./ClickAwayComponent";
import { SYMBOLS } from "./Symbols";
import Image from "next/image";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  token: SupportedToken;
}

const WinsChart: React.FC<ModalProps> = ({ isOpen, onClose, token }) => {
  if (!isOpen) return null;

  const AllSymbols = SYMBOLS(token === "SNEK");
  const prizes = AllSymbols.filter((e) => e.reward > 0);

  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center p-8 text-white ">
      <div className="fixed inset-0 left-0 top-0 h-screen w-screen backdrop-blur-lg "></div>

      <ClickAwayComponent onClickAway={onClose}>
        <div className="relative h-full w-full rounded-lg bg-[#000] shadow-lg md:w-[550px] ">
          <button
            className="absolute right-2 top-2 mr-4 mt-3 text-gray-700 hover:text-gray-900"
            onClick={onClose}
          >
            <CloseIcon color="#fff" />
          </button>
          <div className="p-6">
            <h2 className="text-center text-2xl font-black text-blue-300">
              SPACE RACE PAY TABLE
            </h2>
            <p className="text-center text-[10px] font-black uppercase text-blue-300">
              only one win per spin.
              <br /> in the case of multiple wins only the highest value symbol
              will be paid out
            </p>
            <div className="mt-2">
              <table className="w-full">
                <thead className="text-[#ad14cf]">
                  <tr>
                    <th
                      colSpan={1}
                      className="w-1/2 border-b border-[#fff] py-2"
                    >
                      3 or more Symbols in a Row
                    </th>
                    <th className="w-1/4 border-b border-[#fff] py-2">
                      Payout
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {prizes.map((item, idx) => (
                    <tr className="border-b border-[#ffffff65]" key={idx}>
                      <td className="flex-col -space-y-2 pb-2">
                        <div className="flex w-full">
                          <Image
                            src={item.media}
                            className="h-full w-1/5"
                            alt={item.label}
                            width={80}
                            height={80}
                          />
                          <Image
                            src={item.media}
                            className="h-full w-1/5"
                            alt={item.label}
                            width={80}
                            height={80}
                          />
                          <Image
                            src={item.media}
                            className="h-full w-1/5"
                            alt={item.label}
                            width={80}
                            height={80}
                          />
                          <Image
                            src={item.media}
                            className="h-full w-1/5"
                            alt={item.label}
                            width={80}
                            height={80}
                          />
                          <Image
                            src={item.media}
                            className="h-full w-1/5"
                            alt={item.label}
                            width={80}
                            height={80}
                          />
                        </div>

                        <p className="pl-10 text-center">OR</p>

                        <div className="mx-auto flex w-3/4 items-center gap-16 sm:gap-14">
                          <div className="flex w-full ">
                            <Image
                              src={item.media}
                              className="h-full w-1/2 sm:w-1/3"
                              alt={item.label}
                              width={80}
                              height={80}
                            />
                            <Image
                              src={item.media}
                              className="h-full w-1/2 sm:w-1/3"
                              alt={item.label}
                              width={80}
                              height={80}
                            />
                            <Image
                              src={item.media}
                              className="h-full w-1/2 sm:w-1/3"
                              alt={item.label}
                              width={80}
                              height={80}
                            />
                            <Image
                              src={item.media}
                              className="h-full w-1/2 sm:w-1/3"
                              alt={item.label}
                              width={80}
                              height={80}
                            />
                          </div>
                          <div className="flex w-full ">
                            <Image
                              src={item.media}
                              className="h-full w-1/3 sm:w-1/4"
                              alt={item.label}
                              width={80}
                              height={80}
                            />
                            <Image
                              src={item.media}
                              className="h-full w-1/3 sm:w-1/4"
                              alt={item.label}
                              width={80}
                              height={80}
                            />
                            <Image
                              src={item.media}
                              className="h-full w-1/3 sm:w-1/4"
                              alt={item.label}
                              width={80}
                              height={80}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="border-b border-[#ffffff65] text-center">
                        {item.reward}x
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p className="w-full text-center">
                * Matching symbols must be next to each other
              </p>
            </div>
          </div>
        </div>
      </ClickAwayComponent>
    </div>
  );
};

export default WinsChart;
