/* eslint-disable react-hooks/exhaustive-deps */
import Image from "next/image";
import SlotsContainer from "~/components/SlotsContainer";
import { useGameStore } from "~/utils/zustand";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { BLOCKED_COUNTRIES } from "~/config";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/components/ui/alert-dialog";
import { Checkbox } from "~/components/ui/checkbox";

type Props = {
  country: string;
};
export const getServerSideProps = ({ query }: { query: Props }) => ({
  props: query,
});

export default function Home({ country }: Props) {
  const [showConfirmNotInCountry, setShowConfirmNotInCountry] = useState(false);
  const [checkedConfirmBtn, setCheckedConfirmBtn] = useState(false);

  const gameStore = useGameStore();

  useEffect(() => {
    setShowConfirmNotInCountry(
      BLOCKED_COUNTRIES.some((e) => e.code === country),
    );
  }, []);

  return (
    <>
      {gameStore.currentSelectedToken !== "SNEK" ? (
        <Image
          src="/images/space.jpg"
          className={`img-responsive h-full w-full object-cover blur-md `}
          alt="Space background"
          fill
        />
      ) : (
        <Image
          src="/images/snek-bg.jpg"
          className={`img-responsive h-full w-full object-cover blur-md`}
          alt="Snek background"
          fill
        />
      )}

      <SlotsContainer />

      <AlertDialog open={showConfirmNotInCountry}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Disclaimer</AlertDialogTitle>
            <AlertDialogDescription>
              If you live in the following restricted territories, you are not
              allowed to play on spacerace.thenebula.xyz
              <br></br>
              <br></br>
              <span className="my-2 text-red-500">
                {BLOCKED_COUNTRIES.map((e) => e.name).join(", ")}
              </span>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <div className="flex flex-col items-center gap-5">
              <div
                onClick={() => setCheckedConfirmBtn(!checkedConfirmBtn)}
                className="flex flex-row items-center gap-5 hover:cursor-pointer"
              >
                <Checkbox id="terms" checked={checkedConfirmBtn} />
                <label
                  htmlFor="terms1"
                  className="text-sm text-muted-foreground hover:cursor-pointer"
                >
                  I confirm gambling isn&apos;t forbidden by my local
                  authorities and I&apos;m at least 18 years old.
                </label>
              </div>
              <AlertDialogAction
                onClick={() => {
                  setShowConfirmNotInCountry(false);
                }}
                disabled={!checkedConfirmBtn}
              >
                Confirm
              </AlertDialogAction>
            </div>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
