export type SymbolItem = {
  label: string;
  media: string;
  reward: number;
  id: number;
};

export const NormalSymbols = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
];

export const PrizeSymbols = (isSnek: boolean) => [
  {
    id: 0,
    label: isSnek ? "Bug" : "Saturn",
    reward: 1.5,
    probabilityShowing: Math.pow(0.34 / 9, 1 / 3),
  },
  {
    id: 1,
    label: isSnek ? "Text" : "Rocket",
    reward: 2,
    probabilityShowing: Math.pow(0.34 / 9, 1 / 3) + Math.pow(0.1 / 9, 1 / 3),
  },
  {
    id: 2,
    label: isSnek ? "Gentle" : "Netbula",
    reward: 3,
    probabilityShowing:
      Math.pow(0.34 / 9, 1 / 3) +
      Math.pow(0.1 / 9, 1 / 3) +
      Math.pow(0.02 / 9, 1 / 3),
  },
  {
    id: 3,
    label: isSnek ? "Twice" : "Jetpack",
    reward: 3.6,
    probabilityShowing:
      Math.pow(0.34 / 9, 1 / 3) +
      Math.pow(0.1 / 9, 1 / 3) +
      Math.pow(0.02 / 9, 1 / 3) +
      Math.pow(0.002 / 9, 1 / 3),
  },
  {
    id: 4,
    label: isSnek ? "Can" : "Commet",
    reward: 5,
    probabilityShowing:
      Math.pow(0.34 / 9, 1 / 3) +
      Math.pow(0.1 / 9, 1 / 3) +
      Math.pow(0.02 / 9, 1 / 3) +
      Math.pow(0.002 / 9, 1 / 3) +
      Math.pow(0.0002 / 9, 1 / 3),
  },
  {
    id: 5,
    label: isSnek ? "Muscle" : "Alien",
    reward: 10,
    probabilityShowing:
      Math.pow(0.34 / 9, 1 / 3) +
      Math.pow(0.1 / 9, 1 / 3) +
      Math.pow(0.02 / 9, 1 / 3) +
      Math.pow(0.002 / 9, 1 / 3) +
      Math.pow(0.0002 / 9, 1 / 3) +
      Math.pow(0.0001 / 9, 1 / 3),
  },
];

export const SYMBOLS = (isSnek: boolean) => {
  const prizes = PrizeSymbols(isSnek).map((item) => ({
    label: item.label,
    media: isSnek
      ? `/images/snek_symbols/${item.label.toLowerCase()}.png`
      : `/images/symbols/${item.label.toLowerCase()}.png`,
    reward: item.reward,
    id: item.id,
  }));

  const normal = NormalSymbols.map((item, idx) => ({
    label: item,
    media: isSnek
      ? `/images/snek_symbols/snek_${item.toLowerCase()}.png`
      : `/images/symbols/${item.toLowerCase()}.png`,
    reward: 0,
    id: prizes.length + idx,
  }));

  return prizes.concat(normal);
};
