/* eslint-disable @typescript-eslint/ban-types */
import { type FC } from "react";

interface CoinValueProps {
  title: string;
  coinValue: number;
  setCoinValue: (value: number) => void;
  className: string;
}

const CoinValue: FC<CoinValueProps> = ({
  title,
  coinValue,
  setCoinValue,
  className,
}) => {
  return (
    <div
      className={`input-group flex items-center justify-center ${className}`}
      title={title}
    >
      <input
        className="value-input w-20 border border-yellow-300 bg-background/60 py-0.5 text-center text-[16px] font-bold text-white max-lg:text-[14px] sm:w-24 md:w-28 lg:w-32"
        step={0.1}
        value={coinValue}
        onChange={(e) => setCoinValue(parseFloat(e.target.value))}
        type="number"
        min={0}
      />
    </div>
  );
};

export default CoinValue;
