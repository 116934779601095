import { type FC } from "react";
import type { SymbolItem } from "./Symbols";
import Image from "next/image";

interface CellProps {
  y: number;
  blur: number;
  symbols: SymbolItem[];
  delay: number;
  isSpinMoveEnd: boolean;
  isReset?: boolean;
}

const SlotCell: FC<CellProps> = ({
  y,
  delay,
  symbols,
  isReset,
  isSpinMoveEnd,
  blur,
}) => {
  return (
    <div className=" w-[156px] overflow-hidden bg-[#00000055]">
      <div
        className="smooth-moving flex flex-col items-center justify-center"
        style={{
          transition: `transform ${
            isReset ? 0 : 6 + delay * 2
          }s cubic-bezier(0.25, 0.1, 0, 0.99), filter 2s`,
          transform: `translateY(-${y}px)`,
          filter: `blur(${blur}px)`,
          transitionDelay: isReset ? "0s" : `${delay * 2}s`,
        }}
      >
        {symbols.map((item, idx) => (
          <div
            className={`flex h-[150px] w-[150px] items-center justify-center p-4  ${
              item.reward > 0 && isSpinMoveEnd ? "zoom" : ""
            } max-lg:h-[120px] max-lg:w-[120px] max-md:h-[100px] max-md:w-[100px] max-sm:h-[80px] max-sm:w-[80px]`}
            key={idx}
          >
            <Image
              src={item.media}
              className="h-full w-full"
              alt={item.label}
              width={120}
              height={120}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SlotCell;
