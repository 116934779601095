import Link from "next/link";
import React, { useState } from "react";
import { api } from "~/utils/api";
import { CloseIcon } from "./SvgIcons";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { Button } from "./ui/button";

interface LeaderBoardProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const LeaderBoard: React.FC<LeaderBoardProps> = ({
  isOpen,
  onClose,
}) => {
  const [tab, setTab] = useState("week" as "week" | "month");
  const { data: leaderboardData, status: leaderboardStatus } =
    api.game.getLeaderboard.useQuery({ timePeriod: tab }, { enabled: isOpen });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center text-white ">
      <div className="fixed inset-0 left-0 top-0 h-screen w-screen backdrop-blur-lg "></div>
      <div className=" relative h-[90%] w-[600px] overflow-auto rounded-lg border-2 border-primary-foreground bg-background/50 shadow-lg max-lg:h-screen max-sm:w-[400px] max-[600px]:h-[600px] max-[400px]:w-[300px]">
        <button
          className="absolute right-2 top-2 mr-4 mt-3 text-gray-700 hover:text-gray-900"
          onClick={onClose}
        >
          <CloseIcon color="#fff" />
        </button>
        <div className="flex-col space-y-6 p-6">
          <h2 className="text-center text-2xl font-black text-blue-300">
            SPACE RACE LEADERBOARDS
          </h2>
          <div className="flex flex-col justify-center max-md:space-y-3 md:flex-row md:space-x-6">
            <Button
              className={`rounded-lg border-2 border-white px-6 py-2 text-sm font-semibold ${
                tab === "week" ? "bg-primary" : "bg-secondary"
              }`}
              onClick={() => setTab("week")}
            >
              Current Week
            </Button>
            <Button
              className={`rounded-lg border-2 border-white px-6 py-2 text-sm font-semibold ${
                tab === "month" ? "bg-primary" : "bg-secondary"
              }`}
              onClick={() => setTab("month")}
            >
              Current Month
            </Button>
          </div>

          <Table>
            <TableHeader>
              <TableRow className="border-white">
                <TableHead className="text-center font-extrabold text-white">
                  Wallet
                </TableHead>
                <TableHead className="text-center font-extrabold text-white">
                  Total Played Games
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {leaderboardStatus === "loading" && (
                <TableRow className="border-white/70">
                  <TableCell colSpan={6} className="text-center">
                    Loading...
                  </TableCell>
                </TableRow>
              )}
              {!leaderboardData ||
                (leaderboardData?.length === 0 && (
                  <TableRow className="border-white/70">
                    <TableCell colSpan={6} className="text-center">
                      No-one has played yet, be the first one!
                    </TableCell>
                  </TableRow>
                ))}
              {leaderboardData?.map((data, idx) => (
                <TableRow
                  key={`leaderboard_${tab}_row_${idx}`}
                  className="border-white/70 text-center"
                >
                  <TableCell>{data.played_by}</TableCell>
                  <TableCell>{data.total_played_games}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};
