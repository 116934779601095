import { type FC } from "react";
import ClickAwayComponent from "./ClickAwayComponent";
import { CloseIcon } from "./SvgIcons";
import { cn } from "~/utils/shadcn";
import { api } from "~/utils/api";
import { type Prisma } from "@prisma/client";

interface GameFeeProps {
  isOpen: boolean;
  onClose: () => void;
}

const GameFee: FC<GameFeeProps> = ({ isOpen, onClose }) => {
  const { data: tokenSettings } = api.config.tokenSettings.useQuery();

  if (!isOpen) return null;

  function abbreviateNumber(value: number | Prisma.Decimal): string {
    let newValue: string = value.toString();
    if (Number(value) >= 1000 && Number(value) < 1000000) {
      newValue = (Number(value) / 1000).toFixed(0) + "K";
    } else if (Number(value) >= 1000000 && Number(value) < 1000000000) {
      newValue = (Number(value) / 1000000).toFixed(0) + "M";
    } else if (Number(value) >= 1000000000) {
      newValue = (Number(value) / 1000000000).toFixed(0) + "B";
    }
    return newValue.replace(/\.0+$/, ""); // Removes trailing zeros
  }

  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center uppercase text-white">
      <div className="fixed inset-0 left-0 top-0 h-screen w-screen backdrop-blur-lg "></div>
      <ClickAwayComponent onClickAway={onClose}>
        <div className="relative w-[700px] rounded-lg bg-[#000] shadow-lg max-lg:overflow-auto max-sm:w-[400px] max-[600px]:h-[600px] max-[400px]:w-[300px]">
          <button
            className="absolute right-2 top-2 mr-4 mt-3 text-gray-700 hover:text-gray-900"
            onClick={onClose}
          >
            <CloseIcon color="#fff" />
          </button>
          <div className="p-6">
            <h2 className="text-center text-4xl font-black text-blue-300">
              GAME FEES
            </h2>
            <p className="text-md text-center font-black text-blue-300">
              PER SPIN FEE FOR EACH TOKEN
            </p>
            <div className="mt-2">
              <table className="w-full">
                <thead className="text-[#ad14cf]">
                  <tr>
                    <th className="w-1/4 border-b border-[#fff] py-2">
                      TOKEN / COIN
                    </th>
                    <th className="w-1/4 border-b border-[#fff] py-2">FEE</th>
                    <th className="w-1/3 border-b border-[#fff] py-2">
                      MIN. & MAX. BETS
                    </th>
                  </tr>
                </thead>
                <tbody className="text-lg">
                  {tokenSettings?.settings.map((coin, idx) => (
                    <tr
                      className={cn(
                        idx + 1 !== tokenSettings.settings.length &&
                          "border-b border-[#ffffff65] py-10",
                      )}
                      key={`fee_${coin.tokenName}`}
                    >
                      <td className="py-6 pl-10 text-left">{coin.tokenName}</td>
                      <td className="pl-10 text-left">
                        {`${coin.feeAmount.toString()} ${coin.feeToken}`}
                      </td>
                      <td className="pl-10 text-left">
                        {`${abbreviateNumber(coin.minBet)} - ${abbreviateNumber(
                          coin.maxBet,
                        )} ${coin.tokenName}`}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p className="w-full pl-4 text-left text-[10px]">
                * ALL SPIN FEES WILL BE DEDUCTED FROM YOUR BALANCE AT THE
                BEGINING OF EACH SPIN
              </p>
              <p className="w-full pl-4  text-left text-[10px]">
                * ALL BETS ARE INCLUSIVE OF FEES.
              </p>
            </div>
          </div>
        </div>
      </ClickAwayComponent>
    </div>
  );
};

export default GameFee;
