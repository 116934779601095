import { DownArrow } from "./SvgIcons";
import { useGameStore } from "~/utils/zustand";
import { api } from "~/utils/api";

const TokenDropdown = () => {
  const token = useGameStore((state) => state.currentSelectedToken);
  const setToken = useGameStore((state) => state.setCurrentSelectedToken);

  const { data: tokenSettings } = api.config.tokenSettings.useQuery();

  return (
    <div
      className="group absolute -bottom-11 left-24 z-30 flex w-[160px] items-center justify-center text-center text-[16px] font-bold uppercase leading-[16px] text-yellow-300 max-lg:-bottom-6 max-lg:left-16 max-md:bottom-1 max-md:left-12 max-sm:-bottom-8  max-sm:left-0"
      title="Active Token"
    >
      <label className="flex items-center text-xl max-lg:text-lg max-sm:text-[10px]">
        {token} <DownArrow className="duration-300 group-hover:rotate-180" />
      </label>
      <div className="invisible absolute top-[28px] overflow-hidden rounded-md bg-[#431043] uppercase group-hover:visible">
        <ul className="flex flex-col">
          {tokenSettings?.settings.map((item) => (
            <li
              className={`w-full px-5 py-1.5 text-sm ${
                token === item.tokenName ? "active" : ""
              } cursor-pointer hover:bg-[#2e0b2e]`}
              onClick={() => setToken(item.tokenName)}
              key={item.tokenName}
            >
              {`${item.tokenName}`}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TokenDropdown;
